import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from '/src/globals';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import PageTransitionDemos from '/src/page-sections/demo/page-transitions/PageTransitionDemos';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../../components/PageWrapper';
import { METADATA } from '../../../globals';

//////////////////////////

const useStyles = makeStyles( theme => ({
  wrapper: {
    backgroundColor: '#FFF',
  },
}));

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const PageTransitions2 = (props) => {

  const classes = useStyles(props);

  //////

  return ( <div className={clsx(classes.wrapper)}>

    <Helmet>
      <title>Page Transitions{METADATA.titleBarPostfix}</title>
      <meta name="Demo page name" content="Demo content description" />
    </Helmet>

    <PageWrapper>
      <PageTransitionDemos link={PATHS.pageTransitions1}/>
      <DemoNavigation/>
    </PageWrapper>

  </div> )

}

PageTransitions2.propTypes = propTypes;
export default PageTransitions2;
